import { lazy } from "react";
// default Tungsten Pages
export const RegisteredUsers = lazy(() => import('./RegisteredUsers'))
export const Users = lazy(() => import('./Users'))
export const SentEmails = lazy(() => import('./SentEmails'))
export const SentSMS = lazy(() => import('./SentSMS'))
export const SystemSettings = lazy(() => import('./SystemSettings'))
export const Companies = lazy(() => import('./Companies'))
export const Reports = lazy(() => import('./Reports'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Widgets = lazy(() => import('./Widgets'))
export const Invoices = lazy(() => import('./Invoices'))
export const Bills = lazy(() => import('./Bills'))
export const PermissionPresets = lazy(() => import('./PermissionPresets'))
export const EmailTemplates = lazy(() => import('./EmailTemplates'))
export const SMSTemplates = lazy(() => import('./SMSTemplates'))
export const DocumentTemplates = lazy(() => import('./DocumentTemplates'))
export const PaymentAccounts = lazy(() => import('./PaymentAccounts'))
export const PaymentRuns = lazy(() => import('./PaymentRuns'))
export const TimeLogs = lazy(() => import('./TimeLogs'))
export const Budget = lazy(() => import('./Budget'))
export const BackgroundProcesses = lazy(() => import('./BackgroundProcesses'))
export const DisconnectedProcesses = lazy(() => import('./DisconnectedProcesses'))
export const EmailInbox = lazy(() => import('./EmailInbox'))
export const AvailableTags = lazy(() => import('./AvailableTags'))
export const AvailableTagGroups = lazy(() => import('./AvailableTagGroups'))

// customize Pages
export const PaperOrders = lazy(() => import('./CustomPages/PaperOrders'))
export const PaperDespatch = lazy(() => import('./CustomPages/PaperDespatch'))
export const Customers = lazy(() => import('./CustomPages/Customers'))
export const Suppliers = lazy(() => import('./CustomPages/Suppliers'))
export const MyCompany = lazy(() => import('./CustomPages/MyCompany'))
export const CRM = lazy(() => import('./CustomPages/CRM'))
export const CRMIntroducers = lazy(() => import('./CustomPages/Introducers'))
export const EmailCampaigns = lazy(() => import('./CustomPages/EmailCampaigns'))
export const EmailResponses = lazy(() => import('./CustomPages/EmailResponses'))
export const AutoEmailCampaigns = lazy(() => import('./CustomPages/AutoEmailCampaigns'))
export const CRMGroups = lazy(() => import('./CustomPages/CompanyGroups'))